import React, { useState } from 'react'
import {
    Form,
    Col,
    Row,
    Button,
    Nav,
    FloatingLabel
} from 'react-bootstrap';
import Select from "react-select";

const DEFAULT_FILTER = {
    trustee_number: "",
    county: "",
    week: "current"
};

const weeklyOption = [{
    lable: "Previous",
    value: "prev"
},{
    lable: "Current",
    value: "current"
},{
    lable: "Next",
    value: "next"
}]

export default function SearchBar({ onSearch }) {

    const [fields, setFields] = useState(DEFAULT_FILTER);

    const setField = (type, e) => {
        setFields((pre) => ({
            ...pre,
            [type]: e.target.value
        }));
    }

    const handleSearch = (e) => {
        e.preventDefault()
        onSearch(fields);
    }


    return (
        <Form className="my-3" onSubmit={handleSearch}>
            <h3 class="foreclosure-heading">Weekly</h3>
            <hr />
            <Row className="mb-3">
                <Col className={"align-self-end"}>
                    <Select
                        options={weeklyOption}
                        onChange={handleSearch}
                        value={weeklyOption[0]}
                        menuPortalTarget={document.querySelector('body')}
                        placeholder={"Week"}
                    />
                </Col>
            </Row>
        </Form>
    )
}
