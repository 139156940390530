import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardGroup, Button } from 'react-bootstrap'
import moment from "moment"

const stagesOptions = [
  { label: "Active", value: 1 },
  { label: "Closed", value: 2 },
  { label: "Hidden", value: 3 },
  { label: "Delete", value: 4 }
];

const getStageValues = (index) => {
  if(!index) return "";
  var label = stagesOptions.find(x=>x.value==index).label;
  return  label;
}

const getYearFromDate = (date) => {
  if(!date) return "";
  return  moment(date).utcOffset(0, false).format("yyyy")+'-';
}

const getDateFormat = (date) => {
  if(!date) return "";
   return  moment(date).utcOffset(0, false).format("MMMM D, yyyy");
}

const getCurrency = (data) => {
  if(!data) return "";
  return Number(data).toLocaleString("US", {
      style: "currency",
      currency: "USD"
  });
}

const CardDetails = ({heading,values}) => {
  if(values.join("").trim() !== ""){
    return (
      <>
        <Card.Title as="h6">{heading}</Card.Title>
        <Card.Text>
          {
            values.map((value,i) => {
              return (value) ?
                <React.Fragment key={`${heading}${i}`}>
                  <span>
                    {value}
                  </span><br/>
                </React.Fragment> : null
            })
          }
        </Card.Text>
      </>
    );
  } else {
    return null
  }
}


export default function Details({ data, onClose }) {
  useEffect(() => {window.scrollTo(0, 0)}, [])
  if(!data)
    return null;
    
  return (
    <>
      <Row>
        <Col>
          <h3 className="text-left my-4"><i>Trustee Number: {data.trustee_number}</i></h3>       
        </Col>
        <Col>
          <div className="justify-content-end my-4 nav">
            <Button variant="link" onClick={onClose}>Back</Button>
          </div>
        </Col>
      </Row>
      <CardGroup>
        <Card>
          <Card.Header><b>Borrower Information</b></Card.Header>
          <Card.Body>
            <CardDetails heading="Name" values={[data?.Borrower]}></CardDetails>
            <CardDetails heading="APN(s)" values={[data?.apn]}></CardDetails>
            <CardDetails heading="Property Address" values={[data?.Address]}></CardDetails>
            <CardDetails heading="Mailing Address" values={[data?.borrower_address_1,data?.borrower_city, [data?.borrower_state,data?.borrower_zip].join(", ")]}></CardDetails>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header><b>Lender Information</b></Card.Header>
          <Card.Body>
            <CardDetails heading="Lender" values={[data?.ln_name,data?.ln_name2,data?.ln_street,data?.ln_city+', '+data?.ln_state+' '+data?.ln_zip]}></CardDetails>
            <CardDetails heading="Lender Contact" values={[data?.default_contact,data?.default_phone,data?.lender_contact,data?.lender_phone]}></CardDetails>
            <CardDetails heading="Deed Of Trust Recorded On" values={[getDateFormat(data?.dot_date)]}></CardDetails>
            <CardDetails heading="As Document No." values={[getYearFromDate(data?.dot_date)+''+(data?.dot_inst==null?'':data?.dot_inst)]}></CardDetails>
          </Card.Body>
        </Card>
      </CardGroup>
      <CardGroup className="mt-4">
        <Card>
          <Card.Header><b>Trustee Progression</b></Card.Header>
          <Card.Body>
            <Row>
              <Col xs="6">
                <CardDetails heading="Notice Of Default Recorded On" values={[getDateFormat(data?.nod_date)]}></CardDetails>
                <CardDetails heading="As Document No." values={[getYearFromDate(data?.nod_date)+''+(data?.nod_inst==null?'':data?.nod_inst)]}></CardDetails>
                <CardDetails heading="Amount" values={[getCurrency(data?.nod_amount)]}></CardDetails>
              </Col>
              <Col xs="6">
                <CardDetails heading="Notice Of Sale Recorded On" values={[getDateFormat(data?.nos_date)]}></CardDetails>
                <CardDetails heading="As Document No." values={[getYearFromDate(data?.nos_date)+''+(data?.nos_inst==null?'':data?.nos_inst)]}></CardDetails>
                <CardDetails heading="Amount" values={[getCurrency(data?.nos_amount)]}></CardDetails>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </CardGroup>
      <CardGroup className="mt-4">
        <Card>
          <Card.Header><b>Sale Information</b></Card.Header>
          <Card.Body>
          <Card.Title as="h6">Sale Date</Card.Title>
            <Card.Text>
             {[getDateFormat(data.sale_date)==""?"Sale date has not been set":getDateFormat(data.sale_date)]}
            </Card.Text>

            <Card.Title as="h6">Opening Bid</Card.Title>
            <Card.Text>
              {data.opening_bid}
            </Card.Text>

            <Card.Title as="h6">Stage</Card.Title>
            <Card.Text>   
            {[getStageValues(data?.stage)]}
            </Card.Text>
            

            <Card.Title as="h6">Notes</Card.Title>
            <Card.Text>
              {data.notes}
            </Card.Text>

          </Card.Body>
        </Card>
      </CardGroup>
      <p className="mt-4 text-center">For any corrections please email our <a href={'mailto:commercial.foreclosure.cs@firstam.com?subject='+data?.trustee_number}>Trustee Customer Service Team</a></p>
    </>
  )
}
