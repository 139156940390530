import React, { useState, useEffect, useMemo } from "react";

import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";

import { loginRequest, protectedResources } from "../../../authConfig";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import SearchBar from './SearchBar';
import Table from "../../../components/Table/Table";
import {
  DEFAULT_NO_ITEMS_PER_PAGE,
  DEFAULT_PAGINATION_OPTIONS,
  DoReset,
} from "../../../components/Pagination/helpers";
import axios from "axios";
import { Pagination } from "../../../components/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { useErrorOutlet } from '../../../components/Error';
import { Link } from 'react-router-dom';

const Foreclosure = () => {
  const [limit, setLimit] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [offset, setOffset] = useState(0);
  const [numberofItems, setnumberofItems] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [curPage, setCurPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const setError = useErrorOutlet();
  const [data, setData] = useState([]);

  const [currentData, setCurrentData] = useState(null);

  const [filters, setFilters] = useState({
    limit: DEFAULT_NO_ITEMS_PER_PAGE,
    offset: 0,
  });
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  //const [data, setData] = useState(null);
  const history = useHistory();

  const fetchData = () => {
    setIsLoaded(false);
    setError(null);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/foreclosure`, {
        params: {
          ...filters,
          limit: limit,
          offset: offset,
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoaded(true);
      })
      .catch((error) => {
        const { response } = error;
        const err = response?.data ? response?.data : error;
        setError(err);
        setIsLoaded(true);
      });
  };
  
  useEffect(() => {window.scrollTo(0, 0)}, [])

  useEffect(() => {
    fetchData();
  }, [filters, limit, offset]);

  useEffect(() => {
    if (account && inProgress === "none" && !data) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.apiForeClosure.scopes,
          account: account,
        })
        .then((response) => {
          // callApiWithToken(response.accessToken, protectedResources.apiForeClosure.endpoint)
          //     .then(response => setData(response));
        })
        .catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              instance
                .acquireTokenPopup({
                  scopes: protectedResources.apiForeClosure.scopes,
                })
                .then((response) => {
                  // callApiWithToken(response.accessToken, protectedResources.apiForeClosure.endpoint)
                  //     .then(response => setData(response));
                })
                .catch((error) => console.log(error));
            }
          }
        });
    }
  }, [account, inProgress, instance]);

  useEffect(() => {}, [filters, limit, offset]);

  const handleFilter = (data) => {
    setOffset(0);
    setResetPagination(true);
    DoReset(setResetPagination);
    setFilters((prev) => ({
      ...prev,
      ...data,
    }))
  }
  
  const handleClear = (data) => {
    setOffset(0);
    setResetPagination(true);
    DoReset(setResetPagination);
    setFilters((prev) => ({
      ...prev,
      ...data,
    }))
  }
  const handlePaginationChange = (currentPage, itemsPerPage) => {
    let off = 0;

    if (itemsPerPage != numberofItems) {
      window.scrollTo({ top: 200, behavior: "smooth" });
      setCurPage(1);
      setnumberofItems(itemsPerPage);
      DoReset(setResetPagination);
    } else {
      off = (currentPage - 1) * itemsPerPage;
    }

    setLimit(itemsPerPage);
    setOffset(off);
  };

  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: 'id',
        sortable: false,

      },
      {
        Header: "Trustee Number",
        accessor: "trustee_number",
        sortable: false,
        Cell: ({ row: { original = {} } }) => (
          <Link
            to={`/admin/foreclosure/edit/${original.foreclosure_id}`}
          >
            {original.trustee_number}
          </Link>
        ),
      },
      {
        Header: "Borrower",
        accessor: "Borrower",
        sortable: false,
      },
      {
        Header: "Address",
        accessor: "Address",
        sortable: false,
      },
      {
        Header: "Lender",
        accessor: "lender",
        sortable: false,
      },
      {
        Header: "Actions",
        Cell: ({ row: { original = {} } }) => (
          <Button
            onClick={() =>
              history.push(`/admin/foreclosure/edit/${original.foreclosure_id}`)
            }
          >
            Edit
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row className="my-3">
        <Col xs={6}>
          <h3>Trustee List</h3>
        </Col>
        <Col xs={6} className="text-end">
          <Button
            variant="success"
            onClick={() =>
              history.push(`/admin/foreclosure/new`)
            }
          >
           Add New
          </Button>
        </Col>
      </Row>
      <hr />
      <SearchBar onSearch={handleFilter} onClear={handleClear}/>
      {isLoaded ? (
        <Table columns={columns} data={data?.rows || []} defaultSort="id desc" hiddenColumns={["id"]} />
      ) : (
        <div className="d-flex justify-content-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden"></span>
          </Spinner>
        </div>
      )}

      <Pagination
        itemsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
        onPageChange={handlePaginationChange}
        variant="advanced"
        currentPage={curPage}
        itemsCount={data?.count}
        itemsPerPage={numberofItems}
        isReset={isReset}
      />
    </>
  );
};

export const ForeclosureList = () => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Foreclosure />
    </MsalAuthenticationTemplate>
  );
};
