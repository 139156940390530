import { HashRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import { PageLayout } from "./components/AdminLayout/PageLayout";
import FrontLayout from "./components/FrontLayout";
import { Profile } from "./pages/Profile";
import { Login } from "./pages/Login";
import Home from "./pages/Home/Home";
import Details from "./pages/Home/Details"
import CheckRedirect from "./CheckRedirect"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { ForeclosureList } from "./pages/ForeClosure/ForeClosureList/ForeClosureList";
import ForeClosureEdit from "./pages/ForeClosure/ForeClosureEdit/ForeClosureEdit";
import "./styles/app.scss";
import { ErrorDisplayBoundary } from "./components/Error";


const AuthPages = () => {
  return (
    <Switch>
      <Route exact path="/admin/profile">
        <PageLayout>
          <Profile />
        </PageLayout>
      </Route>
      <Route exact path="/admin/foreclosure/">
        <PageLayout>
          <ForeclosureList />
        </PageLayout>
      </Route>
      <Route exact path="/admin">
        <PageLayout>
          <ForeclosureList />
        </PageLayout>
      </Route>
      <Route exact path="/admin/foreclosure/new">
        <PageLayout>
          <ForeClosureEdit />
        </PageLayout>
      </Route>
      <Route exact path="/admin/foreclosure/edit/:id">
        <PageLayout>
          <ForeClosureEdit />
        </PageLayout>
      </Route>
      <Route exact path="/">
          <CheckRedirect auth="1"/>
      </Route>
      <Route exact path="/details/:id">
        <FrontLayout>
          <Details />
        </FrontLayout>
      </Route>
      <Route exact path="/foreclosure">
        <FrontLayout>
            <Home />
        </FrontLayout>
      </Route>
      <Route exact path="/weekly">
        <FrontLayout>
          <Home weekly={true} />
        </FrontLayout>
      </Route>
    </Switch>
  )
}

const UnAuthPages = () => {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/admin">
        <PageLayout>
          <Login />
        </PageLayout>
      </Route>
      <Route exact path="/details/:id">
        <FrontLayout>
          <Details />
        </FrontLayout>
      </Route>
      <Route exact path="/foreclosure">
        <FrontLayout>
          <Home />
        </FrontLayout>
      </Route>
      <Route exact path="/weekly">
        <FrontLayout>
          <Home weekly={true} />
        </FrontLayout>
      </Route>
      <Route exact path="/">
        <CheckRedirect />
      </Route>
    </Switch>
  );
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
// const App = ({ instance }) => {
//   return (
//     <Router>
//       <MsalProvider instance={instance}>
// <PageLayout>
//   <Pages />
// </PageLayout>
//       </MsalProvider>
//     </Router>
//   );
// }

// export default App;

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    //instance.loginRedirect(loginRequest);
  }

  return (
    <div className='App'>
      <Router>
        <ErrorDisplayBoundary>
          <AuthenticatedTemplate>
            <AuthPages />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <UnAuthPages />
          </UnauthenticatedTemplate>
        </ErrorDisplayBoundary>
      </Router>
    </div>
  );
};

export default App;