import classnames from "classnames";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from "react";
import {
  useColumnOrder,
  useExpanded,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import useDidMountEffect from "../../lib/hooks/use-did-mount";
import useViewport from "../../lib/hooks/use-viewport";
import "./table.scss";

const Icon = <div>{' '}</div>;

// Table header sort ions.
const TableSortIcon = ({ column }) => {
  return null;
  if (!column.isSorted) return <Icon iconName="unfold_more" />;
  if (column.isSortedDesc) return <Icon iconName="expand_more" />;
  return <Icon iconName="expand_less" />;
};

TableSortIcon.propTypes = {
  column: PropTypes.shape({
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool
  }).isRequired
};

// Select checkbox
const IndeterminateCheckbox = forwardRef(
  (
    {
      id,
      indeterminate,
      selectionHeaderClicked,
      selectAllHeader,
      className,
      ...rest
    },
    ref
  ) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const classes = classnames(
      "usa-checkbox__label",
      "margin-top-0",
      "text-bold",
      className
    );

    return (
      // <>
      //   <input
      //     id={id}
      //     type="checkbox"
      //     ref={resolvedRef}
      //     onClick={() => {
      //       if (selectionHeaderClicked) selectionHeaderClicked(id);
      //     }}
      //     {...rest}
      //   />
      // </>
      <div className="usa-checkbox">
        <input
          id={id}
          type="checkbox"
          className="usa-checkbox__input"
          ref={resolvedRef}
          onClick={() => {
            if (selectionHeaderClicked) selectionHeaderClicked(id);
          }}
          {...rest}
        />
        <label
          data-testid={`select-row-${id}`}
          className={classes}
          style={{ lineHeight: 0.8 }}
          htmlFor={id}
        >
          {selectAllHeader}
        </label>
      </div>
    );
  }
);

IndeterminateCheckbox.defaultProps = {
  selectionHeaderClicked: undefined,
  selectAllHeader: "",
  className: ""
};
IndeterminateCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  selectionHeaderClicked: PropTypes.func,
  selectAllHeader: PropTypes.string,
  className: PropTypes.string
};

// Table component.
const Table = forwardRef(
  (
    {
      testId,
      columns: dataColumns,
      data,
      renderRowSubComponent,
      onSort,
      noData,
      onRowSelect,
      bordered,
      fullWidth,
      defaultSort,
      expandable,
      selectable,
      selectAllHeader,
      selectAllHeaderClicked,
      selectedRows,
      hiddenColumns
    },
    ref
  ) => {
    // Custom hook to detect screen size.
    const { width } = useViewport();

    const allColumns = useMemo(() => {
      if (expandable)
        return [
          {
            // Make an expander cell
            Header: "", // No header
            id: "expander", // It needs an ID
            sortable: false,
            // eslint-disable-next-line react/prop-types
            Cell: ({ row }) => (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
              <span
                // eslint-disable-next-line react/prop-types
                {...row.getToggleRowExpandedProps()}
                className="standard-table-expand-button-wrapper"
                // eslint-disable-next-line react/prop-types
                data-testid={`row-expander-${row?.id}`}
              >
                {/* eslint-disable-next-line react/prop-types */}
                {/* {row.isExpanded ? (
                  <Icon iconName="expand_more" className="usa-icon--size-4" />
                ) : (
                  <Icon iconName="navigate_next" className="usa-icon--size-4" />
                )} */}
              </span>
            )
          },
          ...dataColumns
        ];

      return dataColumns;
    }, []);

    const prepareSortOrder = useCallback((sortValue) => {
      let id = null;
      let desc = false;

      // Default sort is empty
      if (!sortValue) return "";

      // default sort passed as an array e.g.[['standardItemNumber', 'DESC']]
      if (Array.isArray(sortValue) && sortValue.length) {
        id = sortValue[0][0] || null;

        if (id && sortValue[0][1]?.length) {
          desc = sortValue[0][1].toLowerCase() === "desc";
        }
      } // Default sort passed as string literal e.g. "standardItemNumber DESC"
      else if (typeof sortValue === "string" && sortValue.length) {
        const [field, direction] = sortValue.split(" ");
        id = field;
        desc = direction.toLowerCase() === "desc";
      }

      return { id, desc };
    }, []);

    const tableInstance = useTable(
      {
        columns: allColumns,
        data,
        disableSortRemove: true,
        // Only set manualSortBy true if onSort function is passed as a prop.
        // If manualSortBy is set to false. Sorting happens locally.
        manualSortBy: typeof onSort === "function",
        initialState: {
          selectedRowIds: selectedRows,
          sortBy: [prepareSortOrder(defaultSort)],
          hiddenColumns
        }
      },
      useColumnOrder,
      useSortBy,
      useExpanded, // We can useExpanded to track the expanded state
      // for sub components too!
      useRowSelect,

      (hooks) => {
        if (selectable)
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              sortable: false,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              // eslint-disable-next-line react/prop-types
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  id="select-all-header"
                  className="margin-top-0"
                  selectAllHeader={selectAllHeader}
                  selectionHeaderClicked={selectAllHeaderClicked}
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              // eslint-disable-next-line react/prop-types
              Cell: ({ row }) => (
                <IndeterminateCheckbox
                  // eslint-disable-next-line react/prop-types
                  id={row?.id}
                  // eslint-disable-next-line react/prop-types
                  {...row.getToggleRowSelectedProps()}
                />
              )
            },
            ...columns
          ]);
      }
    );

    const {
      getTableProps,
      getTableBodyProps,
      prepareRow,
      toggleSortBy,
      toggleAllRowsSelected,
      headerGroups,
      rows,
      visibleColumns,
      setColumnOrder,
      state: { sortBy, selectedRowIds },
      selectedFlatRows
    } = tableInstance;

    // Expose instance functions to parent.
    useImperativeHandle(ref, () => ({
      /**
       * Used to toggle select all rows using header checkbox
       * @param {Boolean} set set select all row select or not.
       */
      toggleAllRowsSelected(set = true) {
        // https://react-table.tanstack.com/docs/api/useRowSelect#instance-properties
        toggleAllRowsSelected(set);
      },

      /**
       * Toggle sort direction.
       * @param {string} columnId The column id
       * @param {Boolean} descending descending value true/false
       * @param {Boolean} isMulti multi column sort
       */
      toggleSortBy(columnId, descending, isMulti = false) {
        // https://react-table.tanstack.com/docs/api/useSortBy#instance-properties
        toggleSortBy(columnId, descending, isMulti);
      }
    }));

    // Re-order expander column based on screen size.
    // On mobile, the expander will be the last child of tr
    const updateExpanderPosition = () => {
      let columnOrder = visibleColumns.map((d) => d.id) || [];

      if (columnOrder[0] === "expander" && width <= 479) {
        const [expander, ...rest] = columnOrder;
        // Move expander to last position
        columnOrder = [...rest, expander];
        setColumnOrder(columnOrder);
      } else if (
        columnOrder[columnOrder.length - 1] === "expander" &&
        width > 479
      ) {
        const resetOrder = columnOrder.filter((o) => o !== "expander");
        columnOrder = ["expander", ...resetOrder];
        setColumnOrder(columnOrder);
      }
    };

    // Screen width changes
    useDidMountEffect(() => {
      updateExpanderPosition();
    }, [width]);

    // Fire sort
    useDidMountEffect(() => {
      let order = [];
      sortBy.forEach((sort) => {
        const field = sort?.id ?? "";
        const direction = sort?.desc ? "DESC" : "ASC";

        if (field?.length && !field.includes("`")) {
          // returns "id DESC" or for associated fields "`content.id` ASC"
          order = `\`${field}\` ${direction}`;
        }
      });

      // if onSort handler is present and sort field is available, bubble up sortOrder.
      if (onSort && order !== defaultSort) onSort(order);
    }, [sortBy]);

    // Row select
    useDidMountEffect(() => {
      onRowSelect({ selectedFlatRows, selectedRowIds });
    }, [selectedFlatRows, selectedRowIds]);

    const tableClasses = classnames(
      "table",
      "table-striped",
    //   {
    //     "table--borderless": !bordered,
    //     "table--full-width": fullWidth
    //   }
    );

    const getRowClasses = ({ isExpanded = false, isSelected = false }) => {
      if (isExpanded)
        return classnames({
          "foreclosure-responsive-table__expanded-row": isExpanded
        });

      if (isSelected)
        return classnames({
          "foreclosure-responsive-table__selected-row": isSelected
        });
      return null;
    };

    const noDataToDisplay = () => {
      const noOfCol = expandable ? dataColumns.length + 1 : dataColumns.length;

      return typeof noData === "function" ? (
        noData()
      ) : (
        <tr>
          <td colSpan={noOfCol}>
            <div className="text-center text-bold"> No Result Found</div>
            <div
              style={{
                width: "60px",
                height: "60px",
                border: "5px dashed rgb(216, 216, 216)",
                borderRadius: "6px",
                margin: "6px"
              }}
            >
              {" "}
            </div>
          </td>
        </tr>
      );
    };

    return (
      // TODO: make table scrollable on tablets
      <div
        data-testid="responsive-table-wrapper"
        className="usa-table-container"
      >
        <table
          {...getTableProps()}
          className={tableClasses}
          data-testid={testId}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className={`table-header__${
                      column.accessor
                        ? kebabCase(column.render("Header"))
                        : `no-accessor`
                    }`}
                    //{...column.getHeaderProps(column.getSortByToggleProps())}
                    title={
                      column?.accessor &&
                      `Toggle Sort by ${column.render("Header")}`
                    }
                  >
                    {column.sortable === false ? (
                      column.render("Header")
                    ) : (
                    //   <a
                    //     href="#"
                    //     className="table-header foreclosure-responsive-table-header__sort-button"
                    //     aria-label={`sort by ${column.Header} in ${
                    //       !column.isSorted || column.isSortedDesc
                    //         ? "ascending"
                    //         : "descending"
                    //     } order`}
                    //   >
                        <>
                            <div className="table-header__name">
                            {column.render("Header")}
                            </div>
                            {/* <span className="table__sort-icon">
                            <TableSortIcon column={column} />
                            </span> */}
                        </>
                    //   </a>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!data?.length
              ? noDataToDisplay()
              : rows.map((row) => {
                  prepareRow(row);
                  return (
                    // Use a React.Fragment here so the table markup is still valid
                    <React.Fragment key={row.id}>
                      <tr
                        className={getRowClasses(row)}
                        data-testid={`table-row-${row.id}`}
                      >
                        <th
                          scope="row"
                          data-label={
                            typeof row.cells[0].render("Header") === "string"
                              ? row.cells[0].render("Header")
                              : ""
                          }
                          {...row.cells[0].getCellProps()}
                          className="first-cell order-last"
                        >
                          {row.cells[0].render("Cell")}
                        </th>
                        {row.cells.slice(1).map((cell) => {
                          return (
                            <td
                              data-label={cell.column.Header}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>

                      {/*
                      If the row is in an expanded state, render a row with a
                      column that fills the entire length of the table.
                    */}

                      {row.isExpanded ? (
                        <tr
                          className="afp-responsive-table__sub-component_row"
                          data-testid={`sub-component-${row.id}`}
                        >
                          <td colSpan={visibleColumns.length}>
                            {/*
                            Inside it, call our renderRowSubComponent function. In reality,
                            you could pass whatever you want as props to
                            a component like this, including the entire
                            table instance. But for this example, we'll just
                            pass the row
                          */}
                            {typeof renderRowSubComponent === "function" &&
                              renderRowSubComponent({ row })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
          </tbody>
        </table>
      </div>
    );
  }
);

Table.defaultProps = {
  selectAllHeader: "",
  selectedRows: {},
  testId: "responsive-table",
  expandable: false,
  selectable: false,
  renderRowSubComponent: null,
  bordered: false,
  fullWidth: false,
  defaultSort: "",
  onSort: null,
  noData: null,
  hiddenColumns: [],
  onRowSelect: () => null,
  selectAllHeaderClicked: () => null
};

Table.propTypes = {
  selectAllHeader: PropTypes.string,
  selectedRows: PropTypes.shape(Object),
  testId: PropTypes.string,
  expandable: PropTypes.bool,
  selectable: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRowSubComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]),
  bordered: PropTypes.bool,
  fullWidth: PropTypes.bool,
  defaultSort: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    PropTypes.string
  ]),
  onSort: PropTypes.PropTypes.func, // Could be func or null
  onRowSelect: PropTypes.func,
  noData: PropTypes.func,
  selectAllHeaderClicked: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string)
};

export default Table;