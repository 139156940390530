import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import useApiRequest from "../../../lib/hooks/use-api-request";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useErrorOutlet } from '../../../components/Error';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectCity from "../../../components/ChooseField/SelectCity";
import { joiResolver } from '@hookform/resolvers/joi';
import DatePicker from "react-datepicker";                  
import "react-datepicker/dist/react-datepicker.css";
import Joi from "joi";
import date from "./date.svg"; 

const DateInput = React.forwardRef(({ value, onClick }, ref) => (
  <>
    <input type="text" value={value} className="form-control" id="date"/>
    <span onClick={onClick} ref={ref} className="input-group-append">
      <span className="input-group-text bg-light d-block">
        <i className="fa fa-calendar"></i>
      </span>
    </span>
  </>
));

const schema = Joi.object().keys({
  id: Joi.allow('').allow(null),
  trustee_number: Joi.string().max(20).label('Trustee File Number').required(),
  dot_date: Joi.date().allow('').allow(null),
  dot_inst: Joi.string().allow('').allow(null).max(25).label('D/T Instrument'),
  nod_date: Joi.date().allow('').allow(null),
  nod_inst: Joi.string().max(25).allow('').label('NoD Instrument'),
  nod_amount: Joi.number().allow('').allow(null).label('NoD Amount'),
  nos_date: Joi.date().allow('').allow(null),
  nos_inst: Joi.string().max(25).allow('').allow(null),
  nos_amount: Joi.number().allow('').allow(null).label('NoS Amount'),
  tdus_date: Joi.date().allow('').allow(null),
  tdus_inst: Joi.string().max(25).allow('').allow(null).label('TDUS Instrument'),

  //Property Address
  prop_address_1: Joi.string().max(70).optional().allow('').allow(null).label('Street'),
  prop_zip: Joi.string().regex(/^[0-9 \-]+$/,'numbers and hyphen(-) only').max(10).optional().allow('').allow(null).label('Zip'),
  apn: Joi.string().max(125).optional().allow('').allow(null).label('APN'),

  //Borrower Information
  borrower_name: Joi.string().max(125).allow('').allow(null).label('Borrower Name'),
  borrower_name2: Joi.string().max(70).allow('').allow(null).label('Borrower(c/o)'),
  borrower_address_1: Joi.string().max(70).optional().allow('').allow(null).label('Borrower Street'),
  borrower_city: Joi.string().max(50).optional().allow('').allow(null).label('Borrower City'),
  borrower_state: Joi.string().regex(/^[a-zA-Z]*$/,'alphabets only').max(2).optional().allow('').allow(null).label('Borrower State'),
  borrower_zip: Joi.string().regex(/^[0-9 \-]+$/,'numbers and hyphen(-) only').max(10).optional().allow('').allow(null).label('Zip'),

  //Lender Information
  loan_number: Joi.string().max(35).allow('').allow(null).label('Loan Number'),
  lender_contact: Joi.string().max(50).allow('').allow(null).label('Lender Contact'),
  lender_phone: Joi.string().max(50).allow('').allow(null).label('Lender Phone'),

  //Sale Information
  sale_date: Joi.date().allow('').allow(null),
  opening_bid: Joi.number().precision(3).allow('').allow(null).label('Opening Bid'),
  notes: Joi.string().max(600).allow('').allow(null).label('Notes'),
  stage: Joi.any().allow('').allow(null),

  lender_name: Joi.string().max(70).allow('').allow(null).label('Lender Name'),
  lender_name2: Joi.string().max(70).allow('').allow(null).label('Company Name'),
  lender_address1: Joi.string().max(70).optional().allow('').allow(null).label('Street'),
  lender_city: Joi.string().max(50).optional().allow('').allow(null).label('Lender City'),
  lender_state: Joi.string().regex(/^[a-zA-Z]*$/,'alphabets only').max(2).optional().allow('').allow(null).label('Lender State'),
  lender_zip: Joi.string().regex(/^[0-9 \-]+$/,'numbers and hyphen(-) only').max(10).optional().allow('').allow(null).label('Lender Zip'),
  lender_default_contact: Joi.string().max(50).allow('').allow(null).label('Lender Contact Override'),
  lender_default_phone: Joi.string().max(50).allow('').allow(null).label('Lender Phone Override'),
}).unknown(true);

const sqlToDate = (val) => {
  return val ? moment(val,"YYYY-MM-DDTHH:mm:ss").toDate(): null;
};

const stagesOptions = [
  { label: "Active", value: 1 },
  { label: "Closed", value: 2 },
  { label: "Hidden", value: 3 },
  { label: "Delete", value: 4 }
];

const ForeClosureEdit = () => {
  const { 
    register, 
    control, 
    formState: { errors }, 
    handleSubmit, 
    reset, 
    watch, 
    setValue,
    getValues 
  } = useForm({
    "stage": stagesOptions[0],
    resolver: joiResolver(schema)
  });
  
  const [isSubmtting, setIsSubmitting] = useState(false);
  const [stateOption, setStateOption] = useState([]);
  const [cntyOption, setCntyOption] = useState([]);
  const [posterror, setPostError] = useState(null);
  const { id } = useParams();
  const isNew = id ? false : true;
  const history = useHistory();

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const postData = {
      ...data,
      dot_date: data?.dot_date ? moment(data?.dot_date).format('YYYY-MM-DD'): null,
      nod_date: data?.nod_date ? moment(data?.nod_date).format('YYYY-MM-DD'): null,
      nos_date: data?.nos_date ? moment(data?.nos_date).format('YYYY-MM-DD'): null,
      tdus_date: data?.tdus_date ? moment(data?.tdus_date).format('YYYY-MM-DD'): null,
      sale_date: data?.sale_date ? moment(data?.sale_date).format('YYYY-MM-DD'): null,
      stage: data?.stage && data.stage?.value ? data.stage.value : 1,
      county_id: data?.county && data.county?.value ? data.county.value : 0,
      city_id: data?.city && data.city?.value ? data.city.value : 0,
      state_id: data?.state && data.state?.value ? data.state.value : 0,
    }

    if (isNew) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/foreclosure`, postData)
        .then((response) => {
          setIsSubmitting(false);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
          history.push(`/admin/foreclosure`)
        })
        .catch((error) => {
          setPostError(error);
          setIsSubmitting(false);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/foreclosure/${id}`, postData)
        .then((response) => {
          setIsSubmitting(false);
          //setData(response.data);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          
          fetchData();
        })
        .catch((error) => {
          setPostError(error);
          setIsSubmitting(false);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const normalizeInput = (e) => {
    let value = e.target.value;
    let previousValue = getValues('lender_default_phone');
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    
    if (value.length != previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
    return currentValue;
  };

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  
  const fetchData = () => {
    setIsLoaded(false);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/foreclosure/${id}`)
      .then(response => {
        setIsLoaded(true);
        setData(response.data);
      })
      .catch(error => {
        setError(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data?.data) {
      reset({
        ...data.data.foreclosure,
        lender: data.data.lender,
        dot_date: sqlToDate(data.data.foreclosure.dot_date),
        nod_date: sqlToDate(data.data.foreclosure.nod_date),
        nos_date: sqlToDate(data.data.foreclosure.nos_date),
        tdus_date: sqlToDate(data.data.foreclosure.tdus_date),
        sale_date: sqlToDate(data.data.foreclosure.sale_date),
        stage: stagesOptions.find((d) => d.value === data?.data?.foreclosure?.stage),
        city: {
          label: data.data.foreclosure.city,
          value: data.data.foreclosure.ct_id,
          st_label: data.data.foreclosure.abbr,
          st_value: data.data.foreclosure.st_id,
          cnty_label: data.data.foreclosure.county,
          cnty_value: data.data.foreclosure.cn_id
        },
        state: {
          label: data.data.foreclosure.abbr,
          value: data.data.foreclosure.st_id,
        },
        county: {
          label: data.data.foreclosure.county,
          value: data.data.foreclosure.cn_id,
        },
        lender_name: data.data.lender.name,
        lender_name2: data.data.lender.name2,
        lender_address1: data.data.lender.address1,
        lender_city: data.data.lender.city,
        lender_state: data.data.lender.state,
        lender_zip: data.data.lender.zip,
        lender_default_contact: data.data.lender.default_contact,
        lender_default_phone: data.data.lender.default_phone
      });
    }
  }, [data]);

  const streetField = watch('prop_address_1');
  const cityField = watch('city');
  const stateField = watch('state');
  const zipField = watch('prop_zip');

  useEffect(() => {
    if(cityField && cityField?.st_label){
      setValue('state',{
        label: cityField.st_label,
        value: cityField.st_value
      })

      setStateOption([
        {
          label: cityField.st_label,
          value: cityField.st_value
        }
      ]);

      setValue('county',{
        label: cityField.cnty_label,
        value: cityField.cnty_value
      });

      setCntyOption([
        {
          label: cityField.cnty_label,
          value: cityField.cnty_value
        }
      ]);
    }
  },[cityField])

  const handleCopyAddr = () => {
    setValue('borrower_address_1', streetField)
    setValue('borrower_city', cityField?.label ? cityField?.label : '')
    setValue('borrower_state', stateField?.label ? stateField?.label : '')
    setValue('borrower_zip', zipField)
  }

  //   return (
  //     <form onSubmit={handleSubmit(onSubmit)}>
  // <Controller
  //     name="firstName"
  //     control={control}
  //     defaultValue=""
  //     render={({ field }) => <Input {...field} />}
  // />
  //       <Controller
  //         name="iceCreamType"
  //         control={control}
  //         render={({ field }) => <Select
  //           {...field}
  //           options={[
  //             { value: "chocolate", label: "Chocolate" },
  //             { value: "strawberry", label: "Strawberry" },
  //             { value: "vanilla", label: "Vanilla" }
  //           ]}
  //         />}
  //       />
  //       <input type="submit" />
  //     </form>
  //   );

  const getForm = () => {
    return (<>
      <div className="my-4 red">
        <i>Please Note : All dates associated with documents are recording dates, not dated dates. 
        Don’t forget to remove the date information and leading zeros out of the recording number.</i>
      </div>
      <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="section1">
          
          <Row className="my-3">
            <Col xs={6}>
              <h3>File &amp; Recording Information</h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                variant="secondary"
                onClick={() =>
                  history.push(`/admin/foreclosure`)
                }
              >
                Back
              </Button>
            </Col>
          </Row>
          <hr />
          <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
            <Form.Label column sm={3}>
              Trustee File Number <span className="required-star">*</span>
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="trustee_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Trustee File Number"
                    isInvalid={errors?.trustee_number}
                    {...field}
                  />
                )}
              />
              {errors?.trustee_number && <Form.Control.Feedback type="invalid">
                  {errors?.trustee_number.message}
              </Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="DTDate">
            <Form.Label column sm={3}>
              D/T Date
            </Form.Label>
            <Col sm={4}>
              <Controller
                control={control}
                name="dot_date"
                render={({ field }) => (
                  <DatePicker
                    className={"form-control"}
                    placeholderText="D/T Date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date(9999,12,31)}
                  />
                )}
              />
              {errors?.dot_date && <Form.Control.Feedback type="invalid">
                  {errors?.dot_date.message}
              </Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="DTInstrument">
            <Form.Label column sm={3}>
              D/T Instrument
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="dot_inst"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder=" D/T Instrument"
                    isInvalid={errors?.dot_inst}
                    {...field}
                  />
                )}
              />
              {errors?.dot_inst && <Form.Control.Feedback type="invalid">
                  {errors?.dot_inst.message}
              </Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="nodDate">
            <Form.Label column sm={3}>
              NoD Date
            </Form.Label>
            <Col sm={4}>
              <Controller
                control={control}
                name="nod_date"
                render={({ field }) => (
                  <DatePicker
                    className={"form-control"}
                    placeholderText="NoD Date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date(9999,12,31)}
                  />
                )}
              />
              {errors?.nod_date && <Form.Control.Feedback type="invalid">
                  {errors?.nod_date.message}
              </Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="nodInst">
            <Form.Label column sm={3}>
              NoD Instrument
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="nod_inst"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="NoD Instrument"
                    isInvalid={errors?.nod_inst}
                    {...field}
                  />
                )}
              />
              {errors?.nod_inst && <Form.Control.Feedback type="invalid">
                  {errors?.nod_inst.message}
              </Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="nodAmount">
            <Form.Label column sm={3}>
              NoD Amount
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="nod_amount"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                   type="text"  
                   placeholder="NoD Amount" 
                   isInvalid={errors?.nod_amount}
                   {...field} />
                )}
              />
              {errors?.nod_amount && <Form.Control.Feedback type="invalid">
                  {errors?.nod_amount.message}
              </Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="nosDate">
            <Form.Label column sm={3}>
              NoS Date
            </Form.Label>
            <Col sm={4}>
              <Controller
                control={control}
                name="nos_date"
                render={({ field }) => (
                  <DatePicker
                    className={"form-control"}
                    placeholderText="NoS Date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date(9999,12,31)}
                  />
                )}
              />
              {errors?.nos_date && <Form.Control.Feedback type="invalid">
                  {errors?.nos_date.message}
              </Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="NoSInstrument">
            <Form.Label column sm={3}>
              NoS Instrument
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="nos_inst"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="NoS Instrument"
                    isInvalid={errors?.nos_inst}
                    {...field}
                  />
                )}
              />
              {errors?.nos_inst && <Form.Control.Feedback type="invalid">
                  {errors?.nos_inst.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="nosAmount">
            <Form.Label column sm={3}>
              NoS Amount
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="nos_amount"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control 
                    type="text"
                    placeholder="NoS Amount" 
                    isInvalid={errors?.nos_amount}
                    {...field} />
                )}
              />
              {errors?.nos_amount && <Form.Control.Feedback type="invalid">
                  {errors?.nos_amount.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="tdusDate">
            <Form.Label column sm={3}>
              TDUS Date
            </Form.Label>
            <Col sm={4}>
              <Controller
                control={control}
                name="tdus_date"
                render={({ field }) => (
                  <DatePicker
                    className={"form-control"}
                    placeholderText="TDUS Date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date(9999,12,31)}
                  />
                )}
              />
              {errors?.tdus_date && <Form.Control.Feedback type="invalid">
                  {errors?.tdus_date.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="TDUSInstrument">
            <Form.Label column sm={3}>
              TDUS Instrument
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="tdus_inst"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="TDUS Instrument"
                    isInvalid={errors?.tdus_inst}
                    {...field}
                  />
                )}
              />
              {errors?.tdus_inst && <Form.Control.Feedback type="invalid">
                  {errors?.tdus_inst.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
        </div>
        <div className="section2">
          <h3>Property Address</h3>
          <hr />
          <Form.Group as={Row} className="mb-2" controlId="street">
            <Form.Label column sm={3}>
              Street
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="prop_address_1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control 
                    type="text" 
                    isInvalid={errors?.prop_address_1}
                    placeholder="Street" {...field} />
                )}
              />
              {errors?.prop_address_1 && <Form.Control.Feedback type="invalid">
                  {errors?.prop_address_1.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="city">
            <Form.Label column sm={3}>
              City
            </Form.Label>
            <Col sm={4}>
            <SelectCity name="city" control={control} />
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="state">
            <Form.Label column sm={3}>
              State
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="state"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return <Select
                      options={stateOption}
                      inputRef={ref}
                      onChange={onChange}
                      value={value}
                      isSearchable={false}
                      isDisabled={true}
                      menuPortalTarget={document.querySelector('body')}
                      placeholder={"Select State"}
                      components={{
                        Menu: () => null,
                        MenuList: () => null,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                  />
                }}
              />
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="County">
            <Form.Label column sm={3}>
              County
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="county"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return <Select
                      options={cntyOption}
                      inputRef={ref}
                      onChange={onChange}
                      value={value}
                      isSearchable={false}
                      isDisabled={true}
                      menuPortalTarget={document.querySelector('body')}
                      placeholder={"Select County"}
                      components={{
                        Menu: () => null,
                        MenuList: () => null,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                  />
                }}
              />
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="propzip">
            <Form.Label column sm={3}>
              Zip
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="prop_zip"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  
                  <Form.Control isInvalid={errors?.prop_zip} type="text" placeholder="Zip" {...field} />
                )}
              />
              {errors?.prop_zip && <Form.Control.Feedback type="invalid">
                  {errors?.prop_zip.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
  {
          <Form.Group as={Row} className="mb-2" controlId="apn">
            <Form.Label column sm={3}>
              APN
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="apn"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                   type="text"
                   placeholder="APN"
                   isInvalid={errors?.apn}
                   {...field} 
                  />
                )}
              />
              {errors?.apn && <Form.Control.Feedback type="invalid">
                  {errors?.apn.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group> }
        </div>
        <div className="section3">
          <h3>Borrower Information</h3>
          <hr />
          <Form.Group as={Row} className="mb-2" controlId="borrowername">
            <Form.Label column sm={3}>
              Borrower Name
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="borrower_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Borrower Name"
                    isInvalid={errors?.borrower_name}
                    {...field}
                  />
                )}
              />
              {errors?.borrower_name && <Form.Control.Feedback type="invalid">
                  {errors?.borrower_name.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="borrower_name2">
            <Form.Label column sm={3}>
              Borrower (c/o)
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="borrower_name2"
                control={control}
                defaultValue=""
                
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Borrower (c/o)"
                    {...field}
                    isInvalid={errors?.borrower_name2}
                  />
                )}
              />
              {errors?.borrower_name2 && <Form.Control.Feedback type="invalid">
                  {errors?.borrower_name2.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
  
          <Form.Group as={Row} className="mb-2" controlId="borrower_address_1">
            <Form.Label column sm={3}>
              Street
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="borrower_address_1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control type="text" isInvalid={errors?.borrower_address_1} placeholder="Street" {...field} />
                )}
              />
              {errors?.borrower_address_1 && <Form.Control.Feedback type="invalid">
                  {errors?.borrower_address_1.message}</Form.Control.Feedback>}
            </Col>
            <Col sm={4}>
              <Button onClick={handleCopyAddr}>Copy Property Address</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="borrower_city">
            <Form.Label column sm={3}>
              City
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="borrower_city"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control type="text" placeholder="City" isInvalid={errors?.borrower_city} {...field} />
                )}
              />
              {errors?.borrower_city && <Form.Control.Feedback type="invalid">
                  {errors?.borrower_city.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="borrower_state">
            <Form.Label column sm={3}>
              State
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="borrower_state"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control type="text" placeholder="State" isInvalid={errors?.borrower_state} {...field} />
                )}
              />
              {errors?.borrower_state && <Form.Control.Feedback type="invalid">
                  {errors?.borrower_state.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="borrower_zip">
            <Form.Label column sm={3}>
              Zip
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="borrower_zip"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control isInvalid={errors?.borrower_zip} type="text" placeholder="Zip" {...field} />
                )}
              />
              {errors?.borrower_zip && <Form.Control.Feedback type="invalid">
                  {errors?.borrower_zip.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
        </div>
        <div className="section3">
          <h3>Lender Information</h3>
          <hr />
          <Form.Group as={Row} className="mb-2" controlId="loan_number">
            <Form.Label column sm={3}>
              Loan Number
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="loan_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Loan Number"
                    isInvalid={errors?.loan_number}
                    {...field}
                  />
                )}
              />
              {errors?.loan_number && <Form.Control.Feedback type="invalid">
                  {errors?.loan_number.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="lender_contact">
            <Form.Label column sm={3}>
              Lender Contact
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="lender_contact"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Lender Contact"
                    isInvalid={errors?.lender_contact}
                    {...field}
                  />
                )}
              />
              {errors?.lender_contact && <Form.Control.Feedback type="invalid">
                  {errors?.lender_contact.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="lender_phone">
            <Form.Label column sm={3}>
              Lender Phone
            </Form.Label>
            <Col sm={4}>
              <Controller
                name="lender_phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Lender Phone"
                    isInvalid={errors?.lender_phone}
                    {...field}
                    onChange={(e) => {  const value = normalizeInput(e); e.target.value = value; field.onChange(e); }}
                  />
                )}
              />
              {errors?.lender_phone && <Form.Control.Feedback type="invalid">
                  {errors?.lender_phone.message}</Form.Control.Feedback>}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="lenderName">
                      <Form.Label column sm={3}>
                          Lender Name
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_name"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="Lender Name" isInvalid={errors?.lender_name}  {...field} />)}
                          />
                          {errors?.lender_name && <Form.Control.Feedback type="invalid">
                          {errors?.lender_name.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
                      <Form.Label column sm={3}>
                          Company Name
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_name2"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="Company Name"  {...field}  isInvalid={errors?.lender_name2} />)}
                          />
                           {errors?.lender_name2 && <Form.Control.Feedback type="invalid">
                          {errors?.lender_name2.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
                      <Form.Label column sm={3}>
                          Street
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_address1"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="Street" {...field}  isInvalid={errors?.lender_address1} />)}
                          />
                           {errors?.lender_address1 && <Form.Control.Feedback type="invalid">
                          {errors?.lender_address1.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
                      <Form.Label column sm={3}>
                          City
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_city"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="City" {...field}   isInvalid={errors?.lender_city}/>)}
                          />
                           {errors?.lender_city && <Form.Control.Feedback type="invalid">
                          {errors?.lender_city.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
                      <Form.Label column sm={3}>
                          State
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_state"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="State" {...field}  isInvalid={errors?.lender_state} />)}
                          />
                           {errors?.lender_state && <Form.Control.Feedback type="invalid">
                          {errors?.lender_state.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
                      <Form.Label column sm={3}>
                          Zip
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_zip"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="Zip" {...field} isInvalid={errors?.lender_zip} />)}
                          />
                            {errors?.lender_zip && <Form.Control.Feedback type="invalid">
                          {errors?.lender_zip.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
                      <Form.Label column sm={3}>
                          Contact Override
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_default_contact"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="Contact Override" {...field} isInvalid={errors?.lender_default_contact} />)}
                          />
                            {errors?.lender_default_contact && <Form.Control.Feedback type="invalid">
                          {errors?.lender_default_contact.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
  
                  <Form.Group as={Row} className="mb-2" controlId="trusteeFileNumberId">
                      <Form.Label column sm={3}>
                          Phone Override
                      </Form.Label>
                      <Col sm={4}>
                          <Controller
                              name="lender_default_phone"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (<Form.Control type="text" placeholder="Phone Override" {...field} onChange={(e) => {  const value = normalizeInput(e); e.target.value = value; field.onChange(e); }} isInvalid={errors?.lender_default_phone} />)}
                          />
                            {errors?.lender_default_phone && <Form.Control.Feedback type="invalid">
                          {errors?.lender_default_phone.message}</Form.Control.Feedback>}
                      </Col>
                  </Form.Group>
              </div>
              <div className="section3">
                <h3>Sale Information</h3>
                <hr />
                <Form.Group as={Row} className="mb-2" controlId="sale_date">
                  <Form.Label column sm={3}>
                    Sale Date
                  </Form.Label>
                  <Col sm={4}>
                    <Controller
                      control={control}
                      name="sale_date"
                      render={({ field }) => (
                        <DatePicker
                          className={"form-control"}
                          placeholderText="Sale Date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy"
                          maxDate={new Date(9999,12,31)}
                        />
                      )}
                    />
                    {errors?.sale_date && <Form.Control.Feedback type="invalid">
                  {errors?.sale_date.message}
              </Form.Control.Feedback>}
                  </Col>
                </Form.Group>
  
                <Form.Group as={Row} className="mb-2" controlId="opening_bid">
                  <Form.Label column sm={3}>
                    Opening Bid
                  </Form.Label>
                  <Col sm={4}>
                    <Controller
                      name="opening_bid"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Opening Bid"
                          isInvalid={errors?.opening_bid}
                          {...field}
                        />
                      )}
                    />
                     {errors?.opening_bid && <Form.Control.Feedback type="invalid">
                  {errors?.opening_bid.message}
              </Form.Control.Feedback>}
                  </Col>
                </Form.Group>
  
                <Form.Group as={Row} className="mb-2" controlId="stage">
                  <Form.Label column sm={3}>
                    Stage
                  </Form.Label>
                  <Col sm={4}>
                      <Controller
                        name="stage"
                        control={control}
                        render={({ field: { onChange, ref, value } }) => {
                          return <Select
                              options={stagesOptions}
                              inputRef={ref}
                              onChange={onChange}
                              value={value}
                              menuPortalTarget={document.querySelector('body')}
                          />
                        }}
                      />
                  </Col>
                </Form.Group>
  
                <Form.Group as={Row} className="mb-2" controlId="notes">
                  <Form.Label column sm={3}>
                    Notes
                  </Form.Label>
                  <Col sm={4}>
                    <Controller
                      name="notes"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Form.Control 
                        as="textarea" 
                        placeholder="Notes"
                        isInvalid={errors?.notes}
                        {...field} />
                      )}
                    />
                    {errors?.notes && <Form.Control.Feedback type="invalid">
                  {errors?.notes.message}
              </Form.Control.Feedback>}
                  </Col>
                </Form.Group>
  
                {!isNew && (
                  <Controller
                    name="foreclosure_id"
                    control={control}
                    defaultValue={
                      stagesOptions[0]
                    }
                    render={({ field }) => (
                      <Form.Control type="hidden" placeholder="Stage" {...field} />
                    )}
                  />
                )}
              </div>
              <hr className="mt-4" />
              <Row>
                <Col xs={12}>
                  <Button 
                    variant="secondary"
                    onClick={() =>
                      history.push(`/admin/foreclosure`)
                    } 
                    size="lg">Cancel</Button>
                  <Button className="mx-2" size="lg" type="submit">
                    { isSubmtting ? (<><Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"/>
                      <span> Saving...</span></>) : "Save" }
                  </Button>
                </Col>
              </Row>
            </form>
      </>)
  }

  return <>
  <ToastContainer /> 
  {isLoaded || isNew ? (getForm()) : (
    <div className="d-flex justify-content-center my-5">
      <Spinner animation="border" role="status">
        <span className="visually-hidden"></span>
      </Spinner>
    </div>
  )}
  </>;
};

/*
New Rules: Stage Defined
1 - Live Active File (Public can see)
2 - Live Closed File (Public can see)
3 - Non-Live File (Public cannot see)
4 - Marked for Deletion (public cannot see)
  */

export default ForeClosureEdit;
