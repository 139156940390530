import React, { useState } from 'react'
import {
    Form,
    Col,
    Row,
    Button,
    Nav,
    FloatingLabel
} from 'react-bootstrap';

const DEFAULT_FILTER = {
    trustee_number: "",
    county: ""
};

export default function SearchBar({ onSearch, onClear }) {

    const [fields, setFields] = useState(DEFAULT_FILTER);

    const setField = (type, e) => {
        setFields((pre) => ({
            ...pre,
            [type]: e.target.value
        }));
    }

    const handleSearch = (e) => {
        e.preventDefault()
        onSearch(fields);
    }

    const handleReset = () => {
        setFields(DEFAULT_FILTER);
        onClear(DEFAULT_FILTER);
    }

    return (
        <Form className="my-3" onSubmit={handleSearch}>
            <h4 class="foreclosure-heading">Foreclosure Search</h4>
            <hr />
            <Row className="mb-3">
                <Col xs={6}>
                    <FloatingLabel
                        controlId="TrusteeNumber"
                        label="Trustee Number"

                    >
                        <Form.Control
                            type="text"
                            placeholder="Trustee Number"
                            value={fields.trustee_number}
                            onChange={(e) => setField('trustee_number', e)}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={6}>
                    <FloatingLabel
                        controlId="County"
                        label="County"
                    >
                        <Form.Control
                            type="text"
                            placeholder="County"
                            minLength={"3"}
                            onChange={(e) => setField('county', e)}
                            value={fields.county} />
                    </FloatingLabel>
                </Col>
            </Row>
            <Nav>
                <Button onClick={handleReset}   variant="secondary" type="button">
                    Clear
                </Button>
                <Button variant="primary" type="submit" className="mx-2">
                    Search
                </Button>
            </Nav>
        </Form>
    )
}
