import React, { useState, useEffect, useMemo } from 'react';
import SearchBar from './SearchBar';
import WeeklyBar from './WeeklyBar';
import Table from '../../components/Table/Table';
import {
    DEFAULT_NO_ITEMS_PER_PAGE,
    DEFAULT_PAGINATION_OPTIONS,
    DoReset
} from '../../components/Pagination/helpers'
import { Pagination } from '../../components/Pagination/Pagination';
import axios from "axios";
import { Spinner, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom"
import { useErrorOutlet } from '../../components/Error';
import Details from "./Details"

export default function Home({ weekly = false }) {

  const [limit, setLimit] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [offset, setOffset] = useState(0);
  const [numberofItems, setnumberofItems] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [curPage, setCurPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const setError = useErrorOutlet();
  const [data, setData] = useState([]); 
  const [currentData, setCurrentData] = useState(null);

  const [filters, setFilters] = useState({
    limit : DEFAULT_NO_ITEMS_PER_PAGE,
    offset: 0,
  })

  const history = useHistory();
  
  const fetchData = () => {
    setIsLoaded(false);
    setError(null);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/foreclosure`, {
        params : {
          ...filters,
          limit: limit, 
          offset: offset,
          ispublic:'1'
        }
      })
      .then(response => {
        setData
        (response.data);
        setIsLoaded(true);
      })
      .catch(error => {
        const { response } = error;
        const err = response?.data ? response?.data : error;   
        setError(err);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [filters, limit, offset])

  useEffect(() => {
    setResetPagination(true);
  },[data])


  const handleFilter = (data) => {
    setOffset(0);
    // setResetPagination(true);
    // DoReset(setResetPagination);
    setCurPage(1);
    setFilters((prev) => ({
      ...prev,
      ...data,
    }))
  }

  const handleClear = (data) => {
    setOffset(0);
    //  setResetPagination(true);
    //  DoReset(setResetPagination);
     setCurPage(1);
    setFilters((prev) => ({
      ...prev,
      ...data,
    }))
  }

  const handlePaginationChange = (currentPage,itemsPerPage) => {
    let off = 0;

    if(itemsPerPage != numberofItems){
      window.scrollTo({ top:200, behavior: 'smooth' });
      setCurPage(1);
      setnumberofItems(itemsPerPage);
      DoReset(setResetPagination);
    } else {
      setCurPage(currentPage);
      off = (currentPage - 1) * itemsPerPage
    }

    setLimit(itemsPerPage)
    setOffset(off)
  }

  const columns = useMemo(
      () => [
        {
          Header: "id",
          accessor: 'id',
          sortable: false          
        },
        {
          Header: 'Trustee Number',
          accessor: 'trustee_number',
          Cell: ({ row : { original = {} } }) => {
            return <Button 
                    variant="link" 
                    className='trustee_btn'
                    onClick={() => setCurrentData(original)}
                    >{original.trustee_number}</Button>
          },
          sortable: false
        },
        {
          Header: "Borrower",
          accessor: 'Borrower',
          sortable: false
        },
        {
          Header: "Address",
          accessor: 'Address',
          sortable: false
        },
        {
          Header: "Lender",
          accessor: 'lender',
          sortable: false
        },
      ],
      []
    );

  if(currentData) {
    return <Details data={currentData} onClose={() => setCurrentData(null)} />
  } else {
    return (
      <>
        { 
          weekly ? 
          <WeeklyBar onSearch={handleFilter} /> :
          <SearchBar onSearch={handleFilter} onClear={handleClear}/> 
        }
        {
          isLoaded ?
            <Table 
              testId="foreclosureTable"
              columns={columns} 
              data={data?.rows || []}
              defaultSort="id desc"
              hiddenColumns={["id"]}
            /> : 
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
              </Spinner>
            </div>
        }

        <Pagination 
          itemsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
          onPageChange={handlePaginationChange}
          variant="advanced"
          currentPage={curPage}
          itemsCount={data?.count}
          itemsPerPage={numberofItems}
          isReset={isReset}
        />
      </>
    )
  }
}
