import React from 'react';
import AsyncSelect from 'react-select/async';
import { Controller } from "react-hook-form";
import axios from "axios";

const loadOptions = (inputValue, callback) => {
  //(get(inputValue, { params: { ...filter } }));
  axios.get(`${process.env.REACT_APP_BASE_URL}/city`,{ params : {  city : inputValue }})
  .then((response) => {
    callback(response.data)
  });
};

const SelectCity = ({ control, name, onSelectChange }) => {
  const [selectValue,setSelectValue] = React.useState({ inputValue: '' });
  const handleInputChange = (newValue) => {
    const inputValue = newValue; //.replace(/\W/g, '');
    setSelectValue({ inputValue });
    return inputValue;
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <AsyncSelect
              loadOptions={loadOptions}
              isClearable
              defaultOptions
              onInputChange={handleInputChange}
              inputRef={ref}
              value={value}
              onChange={(e) => { 
                onChange(e)
                if(onSelectChange)
                  onSelectChange(e)
              }}
              placeholder={"Select City"}
            />
          );
        }}
      />
    </div>
  );
}

export default SelectCity;