import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import usePagination from "../../lib/hooks/use-pagination";
import "./pagination.scss";
export const calculatePageRange = (itemsCount, currentPage, limit) => {
  const numberOfPages = Math.ceil(itemsCount / limit);
  const start = (currentPage - 1) * limit + 1;
  const end = Math.min(currentPage * limit, itemsCount);
  return { start, end, numberOfPages };
};

export const PaginationIcon = ({ type, disabled, className }) => {
  const classes = classnames(
    { [`page-control-icon--${type}`]: !!type },
    { [`page-control-icon-disabled`]: !!disabled },
    className
  );

  switch (type) {
    case "first":
      return (
        <svg
          aria-hidden="true"
          className={classes}
          data-testid="first-icon"
          focusable={false}
          width="13"
          height="11"
          viewBox="0 0 13 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.77477 0H0V11H2.77477V0ZM12.4867 0L4.16237 5.5L12.4867 11V0Z"
            fill="#FFFFFF"
          />
        </svg>
      );
    case "last":
      return (
        <svg
          aria-hidden="true"
          className={classes}
          data-testid="last-icon"
          focusable={false}
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.918945 11L9.24328 5.5L0.918945 0V11ZM10.6309 11H13.4057V0H10.6309V11Z"
            fill="#FFFFFF"
          />
        </svg>
      );
    case "previous":
      return (
        <svg
          aria-hidden="true"
          className={classes}
          data-testid="previous-icon"
          focusable={false}
          width="9"
          height="11"
          viewBox="0 0 9 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.486816 5.5L8.81114 0V11L0.486816 5.5Z"
            fill="#FFFFFF"
          />
        </svg>
      );

    case "next":
      return (
        <svg
          aria-hidden="true"
          className={classes}
          data-testid="next-icon"
          focusable={false}
          width="9"
          height="11"
          viewBox="0 0 9 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.91895 5.5L0.594646 11L0.594646 0L8.91895 5.5Z"
            fill="#FFFFFF"
          />
        </svg>
      );
    default:
      return null;
  }
};
PaginationIcon.defaultProps = {
  className: "",
  disabled: false
};
PaginationIcon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

// type => first, previous, next, last
const PageControlButton = ({ type, className, ...buttonProps }) => {
  const classes = classnames(
    "usa-button",
    "usa-button--outline",
    "pagination-control-button",
    {
      disabled: buttonProps.disabled
    },
    { [`pagination-control-button--${type}`]: !!type },
    className
  );

  //const label = `Go to ${type} page`;
  const label = ``;

  return (
    <Button
      id={`${type}-button`}
      className={classes}
      aria-label={label}
      {...buttonProps}
    >
      <PaginationIcon type={type} disabled={buttonProps.disabled} />

      <span className="usa-sr-only">{label}</span>
    </Button>
  );
};
PageControlButton.defaultProps = {
  className: ""
};
PageControlButton.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

const PageButton = ({ page, selected, variant, className, ...items }) => {
  const classes = classnames(
    "pagination-page-button",
    { "usa-button--outline": !selected },
    { active: selected },
    { [`usa-button--${variant}`]: selected },
    className
  );

  return (
    <Button
      id={`page-${page}-button`}
      data-testid={`page-${page}-button`}
      className={classes}
      aria-label={selected ? `page ${page}` : `Go to page ${page}`}
      {...items}
    >
      {page}
    </Button>
  );
};
PageButton.defaultProps = {
  selected: false,
  className: ""
};
PageButton.propTypes = {
  page: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string
};

const PageSelectorDropdown = ({ items }) => {
  const selected = items.find((i) => i.selected);

  const value =
    selected && hasOwnProperty.call(selected, "page")
      ? selected.page
      : undefined;

  const [selectedItem, setSelectedItem] = useState(value);

  useEffect(() => {
    if (selectedItem === value) return;
    const currentPage = items.find((i) => {
      if (i && hasOwnProperty.call(i, "page")) return i.page === selectedItem;
      return false;
    });
    // Trigger click
    if (currentPage && currentPage.onClick) {
      currentPage.onClick();
    }
  }, [selectedItem]);

  return (
    <li
      key="dropdown-selector"
      className="page-selector-dropdown padding-y-4 margin-x-2"
    >
      <select
        className="pagination-select"
        value={selectedItem}
        data-testid="dropdown-page-selector"
        onChange={(event) => {
          const selectedValue = Number(event.target.value);
          if (selectedValue) {
            setSelectedItem(selectedValue);
          }
        }}
      >
        {items.map(({ page, type }, index) => {
          const optionKey = index;
          return (
            <option key={optionKey} value={page || ""}>
              {type === "page" && page ? `Page ${page}` : "..."}
            </option>
          );
        })}
      </select>
    </li>
  );
};
PageSelectorDropdown.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired
};

const PageSelectorButtons = ({ items, buttonVariant }) =>
  items.map(({ page, type, selected, ...item }, index) => {
    let selectors = null;
    const key = index;

    if (type === "start-ellipsis" || type === "end-ellipsis") {
      selectors = <span className="group-split">…</span>;
    } else if (type === "page") {
      selectors = (
        <PageButton
          page={page}
          variant={buttonVariant}
          selected={selected}
          {...item}
        />
      );
    }

    return (
      <li className="page-selector-button" key={key}>
        {selectors}
      </li>
    );
  });

PageSelectorButtons.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  buttonVariant: PropTypes.string.isRequired
};

export const Pagination = ({
  id,
  variant,
  currentPage,
  showFirstButton,
  showPreviousButton,
  showNextButton,
  showLastButton,
  buttonVariant,
  disabled,
  className,
  boundaryCount,
  siblingCount,
  onPageChange,
  itemsCount,
  itemsPerPage,
  itemsPerPageOptions,
  isReset
}) => {
  const [activePage, setActivePage] = useState(currentPage);
  const [limit, setLimit] = useState(itemsPerPage);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage,itemsCount, isReset]);

  useEffect(() => {
    setLimit(itemsPerPage);
  }, [itemsPerPage]);

  const { start, end, numberOfPages } = useMemo(
    () => calculatePageRange(itemsCount, activePage, limit),
    [itemsCount, activePage, limit]
  );

  const handlePageChange = (value) => {
    if (value) setActivePage(value);
    if (onPageChange) onPageChange(value, limit);
  };

  const { items } = usePagination({
    page: activePage,
    showFirstButton,
    showLastButton,
    hidePrevButton: !showPreviousButton,
    hideNextButton: !showNextButton,
    count: numberOfPages,
    boundaryCount,
    siblingCount,
    onChange: (page) => handlePageChange(page),
    disabled,
    isReset
  });

  const itemsPerPageClasses = classnames(
    "pagination-items-per-page-selector",
    {
      "desktop:grid-col-auto tablet-lg:grid-col-fill display-flex flex-column flex-justify-center":
        variant === "advanced"
    },
    { hide: variant === "basic" }
  );

  const ItemsPerPageSelect = useMemo(
    () => () => (
      <div className={itemsPerPageClasses}>
        <div className="grid-row grid-gap">
          <div className="grid-col-auto display-flex flex-column flex-justify-center">
            <select
              id="select-items-per-page"
              data-testid="select-items-per-page"
              name="items per page"
              aria-label="items per page"
              className="usa-select margin-top-0"
              value={limit}
              onChange={(event) => {
                const value = Number(event.target.value);
                setLimit(value);
                if (onPageChange) onPageChange(1, value);
                setActivePage(1);
              }}
            >
              {itemsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    ),
    [limit, itemsPerPageOptions]
  );

  const pageNavigatorClasses = classnames(
    "pagination-page-navigator",
    "tablet:grid-col-fill display-flex flex-column flex-justify-center flex-align-center"
  );

  const PageNavigator = useMemo(
    () => () => {
      const [first, previous, next, last] = items.filter(
        ({ type }) =>
          type === "next" ||
          type === "last" ||
          type === "previous" ||
          type === "first"
      );

      const pageSelectors = items.filter(
        ({ type }) =>
          type === "start-ellipsis" ||
          type === "end-ellipsis" ||
          type === "page"
      );

      return (
        <div className={pageNavigatorClasses}>
          <nav
            id="page-navigator"
            className="page-navigator"
            aria-label="pagination selector"
          >
            <ul className="pagination justify-content-center">
              {/* FIRST AND PREVIOUS BUTTONS */}
              <li key="first">
                <PageControlButton type="first" {...first} />
              </li>
              <li key="previous">
                <PageControlButton type="previous" {...previous} />
              </li>

              {/* PAGE BUTTONS AND ELLIPSIS */}
              <PageSelectorDropdown items={pageSelectors} />
              <PageSelectorButtons
                items={pageSelectors}
                buttonVariant={buttonVariant}
              />

              {/* LAST AND NEXT BUTTONS */}
              <li key="next">
                <PageControlButton type="next" {...next} />
              </li>
              <li key="last">
                <PageControlButton type="last" {...last} />
              </li>
            </ul>
          </nav>
        </div>
      );
    },
    [items]
  );

  const pageCounterClasses = classnames(
    "pagination-page-counter",
    "desktop:grid-col-3 tablet-lg:grid-col-fill display-flex flex-column flex-justify-start"
  );

  const PageCounter = useMemo(
    () => () => (
      <div className={pageCounterClasses}>
        <div className="grid-row ">
          <div className="grid-col-auto display-flex flex-column text-center">
            <span
              data-testid="page-counter-range"
              className="page-counter-range"
            >
              {variant === "advanced" && <span>Displaying&nbsp;</span>}
              {`${start} - ${end} of`}  &nbsp;{`${itemsCount} items`}
            </span>
          </div>
        </div>
      </div>
    ),
    [start, end, itemsCount]
  );

  const paginationId = id || variant;

  const classes = classnames("foreclosure-pagination", "grid-col", className);

  // Hide pagination if itemsCount is undefined or itemsCount <= itemsPerPage.
  if (!itemsCount) return null;

  return (
    <div className={classes} data-testid={`pagination-${paginationId}`}>
      <div className="grid-row">
        <ItemsPerPageSelect />

        <PageNavigator />

        <PageCounter />
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  id: "",
  className: "",
  variant: "basic",
  buttonVariant: "primary",
  itemsCount: 0,
  currentPage: 1,
  boundaryCount: 1,
  siblingCount: 1,
  itemsPerPage: 10,
  itemsPerPageOptions: [10, 15, 20, 25, 50],
  showFirstButton: true,
  showPreviousButton: true,
  showNextButton: true,
  showLastButton: true,
  disabled: false,
  isReset: false
};

Pagination.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["basic", "advanced"]),
  buttonVariant: PropTypes.oneOf([
    "primary",
    "secondary",
    "base",
    "accent-cool",
    "accent-warm",
    "outline",
    "inverse"
  ]),
  itemsCount: PropTypes.number,
  currentPage: PropTypes.number,
  boundaryCount: PropTypes.number,
  siblingCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  showFirstButton: PropTypes.bool,
  showPreviousButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  isReset: PropTypes.bool
};
