import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./nav.scss"

import { loginRequest } from "../../authConfig";

export const NavigationBar = () => {

    const { instance,accounts } = useMsal();

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <Navbar bg="primary" variant="dark">
                <a className="navbar-brand" href="/#/admin">Foreclosure Editor</a>
                <AuthenticatedTemplate>
      <NavDropdown title={accounts[0]?.name} id="nav-dropdown" className="ml-auto"> 
        <NavDropdown.Item onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })} className="ml-auto">Sign Out</NavDropdown.Item>
      </NavDropdown>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Nav.Link as={Button} variant="secondary" className="ml-auto d-none" onClick={() => instance.loginRedirect(loginRequest)}>Sign in</Nav.Link>
                </UnauthenticatedTemplate>
            </Navbar>
        </>
    );
};