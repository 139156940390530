import React, { useState, useMemo, useContext } from "react";
import { Alert } from "react-bootstrap";
const ErrorContext = React.createContext()

export function ErrorDisplayBoundary({ children }) {
  const [error, setError] = useState(null)
  const ctx = useMemo(() => ({ error, setError }), [error])

  return <ErrorContext.Provider value={ctx}>{children}</ErrorContext.Provider>
}

export function useErrorOutlet() {
  const errorCtx = useContext(ErrorContext)
  return errorCtx.setError
}

function AlertDismissible({children}) {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        {children}
      </Alert>
    );
  }
  return null;
}


export function ErrorOutlet() {
  const { error } = useContext(ErrorContext)

  return (
    error && (
      <div className="alert-wrapper mt-3">
        <AlertDismissible>
          {error.message}
        </AlertDismissible>
      </div>
    )
  )
}

export function UsingErrorHook() {
  const setError = useErrorOutlet()
  return (
    <>
      <h2>Via hook</h2>
      <button onClick={() => setError(new Error('Ouf, painful!'))}>
        Press to render an error message somewhere
      </button>
      <button onClick={() => setError(null)}>Get rid of it</button>
    </>
  )
}