import { useHistory } from 'react-router-dom';

const CheckRedirect = ({ auth }) => {
  const history = useHistory();

  if(auth){
    history.push("admin");
  } else {
    history.push("foreclosure");
  }

  return null;
};

export default CheckRedirect;