export const DEFAULT_NO_ITEMS_PER_PAGE = 10;

export const DEFAULT_PAGINATION_OPTIONS = undefined;

const RESET_DELAY = 500;

export const DoReset = (doReset) => {
    doReset(true);
    setTimeout(() =>{
        doReset(false);
    }, RESET_DELAY)
}